<template>
    <div class="page">
        <el-row class="tool-bar">
            <el-col :span="12">
                <search-tool v-model="search" @input="$utils('search', getStarosneGrupe)"/>
            </el-col>
            <el-col :span="12" align="right">
                <el-button type="success" icon="el-icon-plus" @click="$utils('goTo', '/starosne-grupe/create')" v-if="$utils('roleUser', 'Starosne_grupe', 'create')">{{ $t('global.novo') }}</el-button>
            </el-col>
        </el-row>
        <el-row>
            <!--Table-->
            <el-table v-if="starosneGrupe.length"
                      :data="starosneGrupe"
                      ref="table"
                      :default-sort="sortBy"
                      @sort-change="sort => $utils('changeSort', sort, getStarosneGrupe)"
                      v-loading="gridLoad"
                      border
                      stripe>
                <el-table-column
                        align="left"
                        prop="naziv"
                        :label="$t('starosneGrupe.naziv')"
                        sortable="custom">
                </el-table-column>
                <el-table-column

                        width="120">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" :content="$t('global.izmena')" placement="top" v-if="$utils('roleUser', 'Starosne_grupe', 'edit')">
                            <el-button type="success" icon="fas fa-pen" @click="$utils('goTo', '/starosne-grupe/' + scope.row.id + '')" size="small"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" :content="$t('global.brisanje')" placement="top" v-if="$utils('roleUser', 'Starosne_grupe', 'delete')">
                            <el-button type="danger" icon="fas fa-trash" @click="$delete('starosneGrupe', scope.row.id)" size="small"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
        </el-row>
        <br>
        <el-row>
            <pagination v-model="pagination" @input="getStarosneGrupe" ref="pagination"/>
        </el-row>
    </div>
</template>

<script>
export default {
	name: 'starosne-grupe-list',
	data() {
		return {
			starosneGrupe: [],
			gridLoad: false,
			pagination: {},
			search: '',
			sortBy: {
				prop: 'naziv',
				order: 'ascending'
			}
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('starosneGrupe.starosneGrupe')
		});

		this.$utils('autoLoad');
		this.$utils('stopLoadingAfter', [this.getStarosneGrupe()]);
	},
	methods: {
		getStarosneGrupe() {
			this.gridLoad = true;
			return this.$get('starosneGrupe', this.$utils('getParams')).then(data => {
				this.starosneGrupe = data.content;
				this.$utils('setPag', data);
				this.$utils('autoSave');
				this.gridLoad = false;
			});
		}
	}
};
</script>
